import React, { useState, useContext, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import Logo from "shared/images/logo.png";
import { booleanContext } from "App";

// Helpers
import { Constants } from "shared/helpers/Constants";
import { checkLang } from "shared/helpers/utils";

function Header(props) {
  const { selectedLang, countryCode, params } = useContext(booleanContext);
  const [show, setShow] = useState(false);
  const [langSelected, setLangSelected] = useState(
    selectedLang === "en"
      ? "English (EN)"
      : isObject(Constants.countryLangCodes[countryCode])
      ? Constants.countryLangCodes[countryCode][selectedLang]
      : Constants.countryLangCodes[countryCode]
  );
  const [countryLanguageArray, setCountryLanguageArray] = useState([]);
  const [countryLanguage, setCountryLanguage] = useState("");

  useEffect(() => {
    if (
      countryCode !== "br" ||
      countryCode !== "ie" ||
      countryCode !== "ke" ||
      countryCode !== "en-gb" ||
      countryCode !== "au"
    ) {
      if (
        countryCode === "be" ||
        countryCode === "sr" ||
        countryCode === "za" ||
        Constants.latamCountryCodes.includes(countryCode)
      ) {
        setCountryLanguageArray(
          Object.values(Constants.countryLangCodes[countryCode])
        );
      } else {
        setCountryLanguage(Constants.countryLangCodes[countryCode]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  function isObject(obj) {
    return obj === Object(obj);
  }

  const handleLanguageSwitch = (e) => {
    if (e.target.innerText === "English (EN)") {
      localStorage.setItem("lang_selected", "en");
      localStorage.setItem("country", countryCode);
      setLangSelected("English (EN)");
      props.getSelectedLang("en");
    } else if (
      countryCode === "be" ||
      countryCode === "sr" ||
      countryCode === "za" ||
      Constants.latamCountryCodes.includes(countryCode)
    ) {
      assignLangCodeToStorage(
        e.target.innerText,
        Constants.countryLangCodes[countryCode]
      );
    } else {
      assignLangCodeToStorage(e.target.innerText, Constants.countryLangCodes);
    }
  };

  function assignLangCodeToStorage(innerText, obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (value === innerText) {
        localStorage.setItem("lang_selected", key);
        localStorage.setItem("country", countryCode);
        setLangSelected(value);
        props.getSelectedLang(key);
      }
    }
  }

  const showDropdown = (e) => {
    setShow(!show);
  };

  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.corteva.com/" target="_blank">
          <Image
            src={Logo}
            alt="Corteva logo"
            className="p-2"
            width="220px"
            height="auto"
          />
        </Navbar.Brand>
        {!checkLang(countryCode, params?.lang || selectedLang) ? (
          ""
        ) : countryCode === "br" ||
          countryCode === "ie" ||
          countryCode === "ke" ||
          countryCode === "en-gb" ||
          countryCode === "au" ? (
          ""
        ) : (
          <>
            <div className="flexarea"></div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <NavDropdown
                  title={langSelected}
                  show={show}
                  onMouseEnter={showDropdown}
                  onMouseLeave={hideDropdown}
                  id="collasible-nav-dropdown"
                >
                  {countryLanguageArray && countryLanguageArray.length > 0 ? (
                    countryLanguageArray.map((language) => (
                      <NavDropdown.Item
                        onClick={handleLanguageSwitch}
                        key={language}
                      >
                        <div className="d-inline">{language}</div>
                      </NavDropdown.Item>
                    ))
                  ) : (
                    <NavDropdown.Item onClick={handleLanguageSwitch}>
                      <div className="d-inline">{countryLanguage}</div>
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={handleLanguageSwitch}>
                    <div className="d-inline">English (EN)</div>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
