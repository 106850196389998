import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import "./App.scss";

// Components
import MyselfEmea from "components/EMEA/Myself";
import MyselfSA from "components/SouthAfrica/Myself";
import MyselfLatam from "components/LATAM/Myself";
import MyselfAPAC from "components/APAC/Myself";
import LegalFormEmea from "components/EMEA/LegalRepresentative";
import LegalFormLatam from "components/LATAM/LegalRepresentative";
import LegalFormAPAC from "components/APAC/LegalRepresentative";
import Header from "shared/components/Header";
import Footer from "shared/components/Footer";
import Notfound from "shared/components/Notfound";

// Helpers
import { Constants } from "shared/helpers/Constants";
import { getLang } from "shared/helpers/utils";

export const booleanContext = React.createContext();

function App() {
  let countryCode = "";
  const countryFromStorage = localStorage.getItem("country") || "";
  const lang = localStorage.getItem("lang_selected") || "";

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (process.env.REACT_APP_CURRENT_ENV === "PROD") {
    countryCode = window.location.href.match(
      /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i
    )[1];
  } else {
    countryCode = params?.lang;
  }

  if (countryFromStorage !== countryCode) {
    localStorage.removeItem("country");
    localStorage.removeItem("lang_selected");
  }

  localStorage.setItem("country", countryCode);

  const [selectedLang, setSelectedLang] = useState(
    lang === "en" ? "en" : getLang(countryCode)
  );

  const getSelectedLang = (lang) => {
    setSelectedLang(lang);
  };

  localStorage.setItem("lang_selected", selectedLang);

  return (
    <div>
      <booleanContext.Provider value={{ selectedLang, countryCode, params }}>
        <Router>
          <Header getSelectedLang={getSelectedLang} countryCode={countryCode} />
          <Switch>
            <Route
              path={`/`}
              exact
              component={
                Constants.latamCountryCodes.includes(countryCode)
                  ? MyselfLatam
                  : countryCode === "za"
                  ? MyselfSA
                  : countryCode === "au"
                  ? MyselfAPAC
                  : MyselfEmea
              }
            />
            <Route
              path={`/LegalRepresentative`}
              exact
              component={
                Constants.latamCountryCodes.includes(countryCode)
                  ? LegalFormLatam
                  : countryCode === "au"
                  ? LegalFormAPAC
                  : LegalFormEmea
              }
            />

            <Route component={Notfound} />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    </div>
  );
}

export default App;
