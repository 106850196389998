import React, { useContext } from "react";
import { booleanContext } from "App";

// Helpers
import { getText } from "shared/helpers/utils";

function AADocument(props) {
  const { selectedLang, countryCode } = useContext(booleanContext);
  const handleText = (attr) => {
    return getText(attr, countryCode, selectedLang, "AADoc");
  };
  return (
    <div className="my-2">
      {props.requestFor === "AuthorizedAgent" ? (
        <div>
          <p>{handleText("description1")}</p>
          <p>
            <strong>{handleText("heading")}</strong>{" "}
            {handleText("description2")}
          </p>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}

export default AADocument;
